// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage
import { useEffect } from 'react'

import * as Sentry from '@sentry/browser'
import { usePostHog } from 'posthog-js/react'

import { PrivateSet, Route, Router, Set, navigate, routes } from '@redwoodjs/router'

import LoadingIndicator from 'src/components/LoadingIndicator'
import RequireCompletedProfileWrapper from 'src/components/RequireCompletedProfileWrapper'
import MultipleListingServicesLayout from 'src/layouts/MultipleListingServicesLayout'
import PostsLayout from 'src/layouts/PostsLayout'
import ProfileMentionsLayout from 'src/layouts/ProfileMentionsLayout'
import ProfilesLayout from 'src/layouts/ProfilesLayout'
import ResponsiveLayout from 'src/layouts/ResponsiveLayout'
import ScaffoldLayout from 'src/layouts/ScaffoldLayout'
import SavedSearchSharesLayout from 'src/layouts/Search/SavedSearchSharesLayout'

import { useAuth } from './auth'

const Routes = () => {
  const { client } = useAuth()
  const posthog = usePostHog()

  useEffect(() => {
    const {
      data: { subscription },
    } = client.auth.onAuthStateChange((event, _session) => {
      if (event === 'PASSWORD_RECOVERY') {
        navigate(routes.auth({ view: 'update_password' }))
      }
      if (event === 'SIGNED_OUT') {
        Sentry.setUser(null)
        posthog.reset()
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [client])

  return (
    <Router useAuth={useAuth}>
      <Route path="/" page={LandingPage} name="landing" />
      <PrivateSet wrap={[ResponsiveLayout, ScaffoldLayout]} roles={['admin', 'brokerage']} unauthenticated="logIn" title="Users" titleTo="users" whileLoadingPage={LoadingIndicator}>
        <Route path="/users/{id}/edit" page={UserEditUserPage} name="editUser" />
        <Route path="/users/{id}" page={UserUserPage} name="user" />
        <Route path="/users" page={UserUsersPage} name="users" />
      </PrivateSet>
      <Set wrap={[ResponsiveLayout]} whileLoadingPage={LoadingIndicator}>
        <Route path="/auth" page={AuthPage} name="auth" />
        <Route path="/login" page={AuthPage} name="logIn" />
        <Route path="/signup" page={AuthPage} name="signUp" />
        <Route path="/privacy-policy" page={PrivacyPolicyPage} name="privacyPolicy" />
        <Route path="/about" page={AboutPage} name="about" />
        <Route path="/contact" page={ContactPage} name="contact" />
        <Route path="/unauthorized" page={UnauthorizedPage} name="unauthorized" />
      </Set>
      <PrivateSet wrap={[ResponsiveLayout]} unauthenticated="logIn" whileLoadingPage={LoadingIndicator} whileLoadingAuth={LoadingIndicator}>
        <Route path="/complete-profile" page={CompleteProfilePage} name="completeProfile" />
        <Route path="/settings" page={SettingsPage} name="settings" />
        <Route path="/search/saved-searches/{id}/edit" page={SearchEditSavedSearchPage} name="searchEditSavedSearch" />
        <Route path="/search/saved-search-shares/{id}/edit" page={SearchSavedSearchShareEditSavedSearchSharePage} name="searchEditSavedSearchShare" />
        <Route path="/search/saved-searches" page={SearchSavedSearchesPage} name="searchSavedSearches" />
      </PrivateSet>
      <PrivateSet wrap={[RequireCompletedProfileWrapper, ResponsiveLayout]} path="/" unauthenticated="logIn" whileLoadingPage={LoadingIndicator} whileLoadingAuth={LoadingIndicator}>
        <PrivateSet wrap={[ScaffoldLayout]} title="Reports" titleTo="reports" unauthenticated="logIn" roles={['admin', 'brokerage']}>
          <Route path="/reports" page={ReportsReportsPage} name="reports" />
          <Route path="/market-center-summary-report" page={ReportsMarketCenterSummaryReportPage} name="marketCenterSummaryReport" />
        </PrivateSet>
        <PrivateSet wrap={[ScaffoldLayout]} title="Market Centers" titleTo="marketCenters" buttonLabel="New Market Center" buttonTo="newMarketCenter" buttonToParams={['brokerageId']} titleToParams={['brokerageId']} unauthenticated="logIn" roles={['admin', 'brokerage']}>
          <Route path="/brokerages/{brokerageId}/market-centers/new" page={MarketCenterNewMarketCenterPage} name="newMarketCenter" />
          <Route path="/brokerages/{brokerageId}/market-centers/{id:Int}/edit" page={MarketCenterEditMarketCenterPage} name="editMarketCenter" />
          <Route path="/brokerages/{brokerageId}/market-centers/{id:Int}" page={MarketCenterMarketCenterPage} name="marketCenter" />
          <Route path="/brokerages/{brokerageId}/market-centers" page={MarketCenterMarketCentersPage} name="marketCenters" />
        </PrivateSet>
        <PrivateSet wrap={[ProfileMentionsLayout]} unauthenticated="logIn">
          <Route path="/profile-mentions/new" page={ProfileMentionNewProfileMentionPage} name="newProfileMention" />
          <Route path="/profile-mentions/{id}/edit" page={ProfileMentionEditProfileMentionPage} name="editProfileMention" />
          <Route path="/profile-mentions/{id}" page={ProfileMentionProfileMentionPage} name="profileMention" />
          <Route path="/profile-mentions" page={ProfileMentionProfileMentionsPage} name="profileMentions" />
        </PrivateSet>
        <PrivateSet wrap={[PostsLayout]} unauthenticated="logIn">
          <PrivateSet roles={['admin', 'brokerage']} unauthenticated="logIn">
            <Route path="/posts/new" page={PostNewPostPage} name="newPost" roles={['admin', 'brokerage']} />
            <Route path="/posts/{id}/edit" page={PostEditPostPage} name="editPost" roles={['admin', 'brokerage']} />
            <Route path="/posts" page={PostPostsPage} name="posts" roles={['admin', 'brokerage']} />
          </PrivateSet>
          <Route path="/posts/{id}" page={PostPostPage} name="post" />
        </PrivateSet>
        <PrivateSet wrap={[MultipleListingServicesLayout]} roles="admin" unauthenticated="logIn">
          <Route path="/mls/new" page={MultipleListingServiceNewMultipleListingServicePage} name="newMultipleListingService" />
          <Route path="/mls/{id:Int}/edit" page={MultipleListingServiceEditMultipleListingServicePage} name="editMultipleListingService" />
          <Route path="/mls/{id:Int}" page={MultipleListingServiceMultipleListingServicePage} name="multipleListingService" />
          <Route path="/mls" page={MultipleListingServiceMultipleListingServicesPage} name="multipleListingServices" />
        </PrivateSet>
        <Route path="/layout" page={LayoutPage} name="layout" />
        <PrivateSet wrap={[ProfilesLayout]} unauthenticated="logIn">
          <Route path="/profiles/{id}" page={ProfileProfilePage} name="profile" />
          <Route path="/profiles/brokerage/{brokerageId}/new" page={ProfileNewProfilePage} name="newProfile" />
          <Route path="/profiles/{id}/edit" page={ProfileEditProfilePage} name="editProfile" />
          <Route path="/profiles" page={ProfileProfilesPage} name="profiles" />
          <Route path="/search-profiles" page={SearchProfilesPage} name="searchProfiles" />
        </PrivateSet>
        <PrivateSet wrap={[ProfilesLayout]} roles={['admin', 'brokerage']} unauthenticated="logIn">
          <Route path="/profiles/brokerage/{brokerageId}/createManyProfiles" page={CreateManyProfilesPage} name="createManyProfiles" />
        </PrivateSet>
        <PrivateSet roles={['admin', 'brokerage', 'agent']} unauthenticated="logIn">
          <Route path="/listings/new" page={NewListingPage} name="newListing" /> {/* Make sure `new` is before the {id} route */}
          <Route path="/listings/{id}/edit" page={EditListingPage} name="editListing" />
          <Route path="/listing/{listingId}/edit/photos" page={ManagePhotosPage} name="managePhotos" />
          <Route path="/listing/{listingId}/profile-listings/new" page={NewProfileListingPage} name="newProfileListing" />
          <Route path="/listing/{listingId}/profile-listings/{id}" page={ProfileListingPage} name="profileListing" />
          <Route path="/listing/{listingId}/profile-listings" page={ProfileListingsPage} name="profileListingsByListingId" />
        </PrivateSet>

        <PrivateSet unauthenticated="logIn">
          <Route path="/home" page={FeedPage} name="feed" />
          <Route path="/listings" page={ListingsPage} name="listings" />
          <Route path="/map-search" page={MapSearchPage} name="mapSearch" />
          <Route path="/listings/profile/{profileId}" page={ListingsPage} name="listingsByProfile" />
          <Route path="/brokerages/{id}/listings" page={BrokerageListingsPage} name="brokerageListings" />
        </PrivateSet>
        <PrivateSet wrap={SavedSearchSharesLayout} unauthenticated="logIn">
          <Route path="/search/saved-search/{savedSearchId}/shares/new" page={SearchSavedSearchShareNewSavedSearchSharePage} name="searchNewSavedSearchShare" />
          <Route path="/search/saved-search-shares/{id}" page={SearchSavedSearchShareSavedSearchSharePage} name="searchSavedSearchShare" />
          <Route path="/search/saved-search-shares" page={SearchSavedSearchShareSavedSearchSharesPage} name="searchSavedSearchShares" />
          <Route path="/search/saved-search/{savedSearchId}/shares" page={SearchSavedSearchShareSavedSearchSharesPage} name="sharesForSavedSearch" />
        </PrivateSet>
        <PrivateSet roles={['admin']} unauthenticated="logIn">
          <Route path="/brokerages/new" page={NewBrokeragePage} name="newBrokerage" />
        </PrivateSet>
        <PrivateSet roles={['admin', 'brokerage']} unauthenticated="logIn">
          <Route path="/brokerages/{id}/edit" page={EditBrokeragePage} name="editBrokerage" />
          <Route path="/brokerages/{id}" page={BrokeragePage} name="brokerage" />
          <Route path="/brokerages" page={BrokeragesPage} name="brokerages" />
          <Route path="/brokerages/{brokerageId}/brokerage-profile-associations/new" page={NewBrokerageProfileAssociationPage} name="newBrokerageProfileAssociationByBrokerage" />
          <Route path="/brokerages/{brokerageId}/brokerage-profile-associations/{profileId}/new" page={NewBrokerageProfileAssociationPage} name="newBrokerageProfileAssociationByBrokerageAndProfile" />
          <Route path="/brokerages/{brokerageId}/brokerage-profile-associations/{id}" page={BrokerageProfileAssociationPage} name="brokerageProfileAssociationByBrokerage" />
          <Route path="/brokerages/{brokerageId}/brokerage-profile-associations/{id}/edit" page={EditBrokerageProfileAssociationPage} name="editBrokerageProfileAssociationByBrokerage" />
          <Route path="/brokerages/{brokerageId}/agents" page={AgentsPage} name="agents" />
        </PrivateSet>
        <PrivateSet wrap={[ScaffoldLayout]} title="Clients" titleTo="clients" buttonTo="newClient" buttonLabel="Invite New Client" secondaryButtonTo="addExistingClient" secondaryButtonLabel="Add Existing Client" roles={['admin', 'agent', 'brokerage']} unauthenticated="logIn">
          <Route path="/clients/new-client" page={NewClientPage} name="newClient" />
          <Route path="/clients/add-existing-client" page={AddExistingClientPage} name="addExistingClient" />
          <Route path="/clients/{agentId}/client/{clientId}" page={ClientPage} name="client" />
          <Route path="/clients/{agentId}" page={ClientsPage} name="clientsByAgentId" />
          <Route path="/clients" page={ClientsPage} name="clients" />
        </PrivateSet>
        <PrivateSet wrap={[ScaffoldLayout]} roles={['admin', 'agent', 'brokerage']} unauthenticated="logIn">
          <Route path="/buyers/profile/{profileId}" page={BuyerMatchesPage} name="buyerMatchesForProfile" />
          <Route path="/buyers/profile/{listingId}" page={BuyerMatchesPage} name="buyerMatchesForListing" />
        </PrivateSet>
        <PrivateSet wrap={[ScaffoldLayout]} title="Notifications" titleTo="notifications" unauthenticated="logIn">
          <PrivateSet unauthenticated="logIn" roles="admin">
            <Route path="/notifications/new" page={NotificationNewNotificationPage} name="newNotification" />
            <Route path="/notifications/{id}/edit" page={NotificationEditNotificationPage} name="editNotification" />
            <Route path="/notifications/{id}" page={NotificationNotificationPage} name="notification" />
          </PrivateSet>
          <PrivateSet unauthenticated="logIn">
            <Route path="/notifications" page={NotificationNotificationsPage} name="notifications" />
          </PrivateSet>
        </PrivateSet>
      </PrivateSet>
      <PrivateSet wrap={[RequireCompletedProfileWrapper]} path="/" unauthenticated="logIn" whileLoadingPage={LoadingIndicator} whileLoadingAuth={LoadingIndicator}>
        <PrivateSet unauthenticated="logIn" roles={['admin', 'brokerage']}>
          <Route path="/listings/{id}/data" page={ListingRawPage} name="listingRaw" />
        </PrivateSet>
        <PrivateSet unauthenticated="logIn">
          <Route path="/listings/{id}" page={ListingPage} name="listing" />
        </PrivateSet>
      </PrivateSet>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
